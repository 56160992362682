<template>
  <div :style="{ color: !data.useFlag ? 'red' : '' }">
    {{ data.className }}
  </div>
</template>

<script>
export default {
  name: "productClassTreeNodeTemplate",
  data() {
    return {
      data: {},
    };
  },
  computed: {
    isAddButtonDisplay() {
      if (this.data.depth === 1 || this.data.depth === 2) {
        return true;
      }
      return false;
    },
  },
};
</script>
