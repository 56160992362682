import request from "@/utils/request";

/**
 * @return Promise
 */
export function getProductClassCodesDepthZero() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/product-class/depthZero",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function getProductClassCodes(searchClass) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/product-class",
    method: "get",
    params: {
      searchClass: searchClass,
    },
  });
}

/**
 * @return Promise
 */
export function putProductClassCodes(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/product-class",
    method: "put",
    data: data,
  });
}

/**
 * @return Promise
 */
export function delProductClassCodes(classCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product/product-class",
    method: "delete",
    params: {
      classCode: classCode,
    },
  });
}

/**
 * @return Promise
 */
export function getAllProductClassCodes() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/product-code/product-class",
    method: "get",
  });
}
