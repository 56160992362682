<template>
  <div class="content-wrapper">
    <div class="content-lookup"></div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">상품분류 조회</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button" style="position: relative">
                <li style="position: absolute; left: 0; top: 0">
                  <ul class="lookupDetail-condition" style="padding: 0">
                    <li class="field" style="margin: 0; padding: 0">
                      <ul class="content" style="width: auto">
                        <li class="item">
                          <ejs-dropdownlist
                            v-model="searchClass"
                            :dataSource="searchClassOptions"
                            :allowFiltering="false"
                            :fields="searchClassFields"
                            cssClass="lookupDetail-condition-dropdown"
                            width="160px"
                            @change="searchClassChange"
                          ></ejs-dropdownlist>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li style="float: right">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="collapseAllTree"
                  >
                    전체닫기
                  </erp-button>
                </li>
                <li style="float: right">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="expandAllTree"
                  >
                    전체열기
                  </erp-button>
                </li>
                <li style="float: right" class="add">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      @click.native="deleteSelectClass"
                  >

                    삭제
                  </erp-button>
                </li>
                <li style="float: right" class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="addChildClass"
                  >
                    하위분류추가
                  </erp-button>
                </li>
                <li style="float: right" class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="addDepth1Class"
                  >
                    대분류추가
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body section-01">
              <div class="body-treeview">
                <ejs-treeview
                  ref="productClassTreeView"
                  id="productClassTreeView"
                  :fields="productClassTreeViewFields"
                  :dataBound="productClassTreeViewDataBound"
                  :nodeTemplate="productClassTreeNodeTemplate"
                  :nodeSelected="productClassTreeNodeSelected"
                  :nodeClicked="productClassTreeNodeClicked"
                />
              </div>
            </div>
          </section>
        </div>
        <div v-if="isDetailViewOpen" class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">상품분류 등록</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-key-color="true"
                        :is-shortcut-button="true"
                        @click.native="saveClassDetail"
                    >
                      저장
                    </erp-button>
                  </li>
                  <li class="reset">
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                        @click.native="initClassDetail"
                    >
                      초기화
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body" style="border: none">
              <div class="body-data" style="height: 100%">
                <div class="data-outer">
                  <div class="data-inner">
                    <ul class="data-content">
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title required">분류명</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  ref="className"
                                  id="className"
                                  name="className"
                                  v-model="classDetailInfo.className"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title required">변환코드 1</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  ref="classCode1"
                                  id="classCode1"
                                  name="classCode1"
                                  v-model="classDetailInfo.classCode1"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">변환코드 2</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.classCode2"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">변환코드 3</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.classCode3"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">변환코드 4</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.classCode4"
                                  style="width: 200px"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">분류설명</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-textarea
                                  v-model="classDetailInfo.classDesc"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title required">정렬값</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <ejs-numerictextbox
                                  ref="sortNo"
                                  id="sortNo"
                                  name="sortNo"
                                  v-model="classDetailInfo.sortNo"
                                  :min="0"
                                  format="n0"
                                  v-on:keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">사용여부</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group check">
                              <!-- 포커스 : focus -->
                              <ul class="check">
                                <li>
                                  <label>
                                    <input
                                      type="radio"
                                      v-model="classDetailInfo.useFlag"
                                      value="true"
                                    />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                                <li>
                                  <label>
                                    <input
                                      type="radio"
                                      v-model="classDetailInfo.useFlag"
                                      value="false"
                                    />
                                    <i></i>
                                    <div class="label">미사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">통합상품명</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.unityProductName"
                                  style="width: 200px"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">통합상품코드</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.unityProductCode"
                                  style="width: 200px"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" style="width: calc(100% + 1px)">
                        <!-- 필수 : required -->
                        <div class="title">공사품목코드</div>
                        <div class="content">
                          <ul class="row">
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  v-model="classDetailInfo.corpProductCode"
                                  style="width: 200px"
                                  @keydown.native="preventEnterEvent"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
                <div class="border-left"></div>
                <div class="border-right"></div>
                <div class="border-top"></div>
                <div class="border-bottom"></div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<style scoped>
.section-body.section-01 >>> .body-treeview {padding: 12px 0;}
.section-body.section-01 >>> .body-treeview .e-treeview {padding: 0 0 0 1px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul {padding: 0 0 0 17px; margin: 0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-fullrow {height: 29px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-list-text {width: calc(100% - 23px); min-height: 29px; line-height: 29px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-ul .e-fullrow {height: 26px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-ul .e-list-text {width: 100%; min-height: 26px; line-height: 26px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-ul .e-ul .e-fullrow {height: 23px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-ul .e-ul .e-ul .e-list-text {min-height: 23px; line-height: 23px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-item {padding: 0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-item.e-hover > .e-fullrow {background-color: #f0f0f0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-item.e-hover .e-list-text {color: #666;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-item.e-active > .e-fullrow {background-color: #edfaf0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-item.e-active > .e-text-content {opacity: 1;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-fullrow {border: none;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-text-content {padding: 0 0 0 8px; border: none;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-icons {width: 8px; height: 8px; margin: 0 0 0 -8px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-icons:before {display: block; width: 100%; height: 100%; padding: 0; background: transparent url('../../../assets/images/common/treeview-arrow.png') no-repeat center center; content: '';}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon {width: 14px; height: 14px; margin: 0 0 0 9px; background: transparent url('../../../assets/images/layout/sideLeft-navigation-icon.png') no-repeat center 0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.favorites {background-position: center 0;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.membershipManagement {background-position: center -14px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.scheduleManagement {background-position: center -28px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.frontManagement {background-position: center -42px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.bondManagement {background-position: center -56px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.storeManagement {background-position: center -70px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.competition {background-position: center -84px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.codeManagement {background-position: center -98px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-icon.rightsManagement {background-position: center -112px;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-text {overflow: hidden; padding: 0 18px 0 9px; color: #666; text-overflow: ellipsis;}
.section-body.section-01 >>> .body-treeview .e-treeview .e-list-text div {display: inline;}
</style>

<script>
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import productClassTreeNodeTemplate from "./ProductClassTreeNodeTemplate";
import {
  getProductClassCodes,
  getProductClassCodesDepthZero,
  putProductClassCodes,
  delProductClassCodes,
} from "@/api/productClassCode";
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ProductClassCode",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputTextarea,
    InputText,
    ErpButton,
  },
  async created() {
    await this.fetchClassListAll();
  },
  data() {
    return {
      validateRefList: {
        className: { required: true },
        classCode1: { required: true },
        sortNo: { required: true },
      },
      isDetailViewOpen: false,
      selectedNode: null,
      classDetailInfo: {
        classCode: null,
        parentCode: null,
        className: null,
        depth: null,
        sortNo: null,
        classCode1: null,
        classCode2: null,
        classCode3: null,
        classCode4: null,
        classDesc: null,
        useFlag: true,
        unityProductName: null,
        unityProductCode: null,
        corpProductCode: null,
      },
      searchClass: null,
      searchClassOptions: [],
      searchClassFields: { text: "className", value: "classCode" },
      productClassTreeViewFields: {},
      isTreeExpanded: true,
      productClassTreeNodeTemplate: function () {
        return {
          template: productClassTreeNodeTemplate,
        };
      },
    };
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    async searchClassChange(args) {
      if (args.isInteracted) {
        // Mouse/Keyboard로 직접 수정했을 때만 다시 불러옴
        await this.fetchClassList();
      }
    },
    productClassTreeViewDataBound() {
      this.$refs.productClassTreeView.expandAll();
    },
    detailViewOpen() {
      // 상세화면 View 처리 시에 FormValidator 생성
      if (!this.isDetailViewOpen) {
        this.isDetailViewOpen = true;
      }
    },
    detailViewClose() {
      this.clearClassDetail();
      this.isDetailViewOpen = false;
    },
    productClassTreeNodeSelected(args) {
      this.selectedNode = args.nodeData;
    },
    productClassTreeNodeClicked(args) {
      if (args.event.which === 3) {
        return;
      }

      const classData = this.getCurrentSelectedTreeNodeData();

      this.classDetailInfo.classCode = classData.classCode;
      this.classDetailInfo.parentCode = classData.parentCode;
      this.classDetailInfo.className = classData.className;
      this.classDetailInfo.depth = classData.depth;
      this.classDetailInfo.sortNo = classData.sortNo;
      this.classDetailInfo.classCode1 = classData.classCode1;
      this.classDetailInfo.classCode2 = classData.classCode2;
      this.classDetailInfo.classCode3 = classData.classCode3;
      this.classDetailInfo.classCode4 = classData.classCode4;
      this.classDetailInfo.classDesc = classData.classDesc;
      this.classDetailInfo.useFlag = classData.useFlag;
      this.classDetailInfo.unityProductName = classData.unityProductName;
      this.classDetailInfo.unityProductCode = classData.unityProductCode;
      this.classDetailInfo.corpProductCode = classData.corpProductCode;

      this.detailViewOpen();
    },
    addDepth1Class() {
      this.initClassDetail();
      this.classDetailInfo.classCode = null;
      this.classDetailInfo.parentCode = this.searchClass;
      this.classDetailInfo.depth = 1;

      this.detailViewOpen();
    },
    addChildClass() {
      if (!this.selectedNode) {
        this.errorToast("하위분류를 등록할 상위분류를 선택해주세요");
        return;
      }

      const { classCode, depth } = this.getCurrentSelectedTreeNodeData();

      if (depth === 3) {
        this.errorToast("소분류에 하위분류를 등록할 수 없습니다");
        return;
      }

      this.initClassDetail();
      this.classDetailInfo.classCode = null;
      this.classDetailInfo.parentCode = classCode;
      this.classDetailInfo.depth = depth + 1;

      this.detailViewOpen();
    },
    async deleteSelectClass() {
      if (!this.selectedNode) {
        this.errorToast("삭제할 분류를 선택해주세요");
        return;
      }

      if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }

      const { classCode } = this.getCurrentSelectedTreeNodeData();

      await this.deleteClass(classCode);
    },
    expandAllTree() {
      this.$refs.productClassTreeView.expandAll();
    },
    collapseAllTree() {
      this.$refs.productClassTreeView.collapseAll();
    },
    getCurrentSelectedTreeNodeData() {
      const treeViewInstance = this.$refs.productClassTreeView.ej2Instances;
      const targetNodeId = this.selectedNode?.id;
      return treeViewInstance.getTreeData(targetNodeId)[0];
    },
    clearClassDetail() {
      this.classDetailInfo.classCode = null;
      this.classDetailInfo.parentCode = null;
      this.classDetailInfo.depth = null;
      this.initClassDetail();
    },
    initClassDetail() {
      // this.classDetailInfo.classCode = null
      // this.classDetailInfo.parentCode = null
      // this.classDetailInfo.depth = null
      this.classDetailInfo.className = null;
      this.classDetailInfo.classCode1 = null;
      this.classDetailInfo.classCode2 = null;
      this.classDetailInfo.classCode3 = null;
      this.classDetailInfo.classCode4 = null;
      this.classDetailInfo.classDesc = null;
      this.classDetailInfo.sortNo = 0;
      this.classDetailInfo.useFlag = true;
      this.classDetailInfo.unityProductName = null;
      this.classDetailInfo.unityProductCode = null;
      this.classDetailInfo.corpProductCode = null;
      this.selectedNode = null;
    },
    async fetchClassListAll() {
      const depthZeroResponse = await getProductClassCodesDepthZero();
      this.searchClassOptions = depthZeroResponse.value.productClassList;
      this.searchClass = this.searchClassOptions[0].classCode;

      await this.fetchClassList();
    },
    async fetchClassList() {
      const {
        value: { productClassList },
      } = await getProductClassCodes(this.searchClass);
      this.selectedNode = null;
      this.productClassTreeViewFields = {
        dataSource: productClassList,
        id: "classCode",
        text: "className",
        child: "childProductClassList",
      };
    },
    async deleteClass(classCode) {
      await delProductClassCodes(classCode);

      this.infoToast(this.$t("main.popupMessage.deleted"));
      this.selectedNode = null;
      this.detailViewClose();

      // 상품 분류 정보 재조회
      await this.fetchClassList();
    },
    async saveClassDetail() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }
      // 상품 분류 정보 추가/수정
      const saveData = {
        productClassInfo: this.classDetailInfo,
      };
      await putProductClassCodes(saveData);

      this.infoToast(this.$t("main.popupMessage.saved"));

      this.selectedNode = null;
      this.detailViewClose();

      // 상품 분류 정보 재조회
      await this.fetchClassList();
    },
  },
};
</script>
